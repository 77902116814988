import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { login } from './utils/api';
import { FaUser, FaLock, FaSignInAlt } from 'react-icons/fa';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [keepSignedIn, setKeepSignedIn] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);
        try {
            const response = await login(username, password);
            console.log('Login response:', response); // Add this line for debugging
            if (response.token && response.refreshToken) {
                localStorage.setItem('token', response.token);
                localStorage.setItem('refreshToken', response.refreshToken);
                localStorage.setItem('username', response.user.username);
                if (keepSignedIn) {
                    // Implement keep signed in functionality if needed
                }
                onLogin(response.user.username);
                navigate('/dashboard');
            } else {
                throw new Error('Token or refresh token missing from response');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError(error.response?.data?.message || 'An error occurred during login. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-container">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">
                        <FaUser /> Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        disabled={isLoading}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">
                        <FaLock /> Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        disabled={isLoading}
                    />
                </div>
                <div className="form-group">
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={keepSignedIn}
                            onChange={(e) => setKeepSignedIn(e.target.checked)}
                            disabled={isLoading}
                        />
                        Keep me signed in
                    </label>
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? (
                        'Logging in...'
                    ) : (
                        <>
                            <FaSignInAlt /> Login
                        </>
                    )}
                </button>
            </form>
            {error && <p className="error-message">{error}</p>}
            <p>
                Don't have an account? <Link to="/register">Register here</Link>
            </p>
        </div>
    );
};

export default Login;
