import React, { useState } from 'react';
import axios from 'axios';

const SolDistributor = ({ username }) => {
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleDistribute = async () => {
        setMessage('');
        setError('');
        setIsProcessing(true);
        setProgress(0);
        try {
            const response = await axios.post('https://calm-peak-49513-45b1323afa7e.herokuapp.com/api/distribute-sol', { amount, username }, {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            });
            setMessage(response.data.message);
        } catch (error) {
            setError('Error distributing SOL: ' + (error.response?.data?.message || error.message));
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="sol-distributor">
            <h3>Distribute SOL</h3>
            <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Amount of SOL to distribute"
            />
            <button onClick={handleDistribute} disabled={isProcessing}>
                {isProcessing ? 'Distributing...' : 'Distribute SOL'}
            </button>
            {isProcessing && (
                <div className="progress-bar">
                    <div className="progress-bar-fill" style={{width: `${progress}%`}}></div>
                </div>
            )}
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default SolDistributor;
