import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaWallet, FaCrosshairs, FaCog, FaChartLine } from 'react-icons/fa';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <nav>
        <ul>
          <li><Link to="/dashboard"><FaHome /> Dashboard</Link></li>
          <li><Link to="/wallets"><FaWallet /> Wallets</Link></li>
          <li><Link to="/token-sniper"><FaCrosshairs /> Token Sniper</Link></li>
          <li><Link to="/pump-volume"><FaChartLine /> Pump Volume</Link></li>
          <li><Link to="/settings"><FaCog /> Settings</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
