import React, { useState } from 'react';
import axios from 'axios';

const SolCollector = ({ username }) => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isCollecting, setIsCollecting] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleCollect = async () => {
        setMessage('');
        setError('');
        setIsCollecting(true);
        setProgress(0);
        try {
            const response = await axios.post('https://calm-peak-49513-45b1323afa7e.herokuapp.com/collect-sol', { username }, {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            });
            setMessage(response.data.message);
        } catch (error) {
            setError('Error collecting SOL: ' + (error.response?.data?.message || error.message));
        } finally {
            setIsCollecting(false);
        }
    };

    return (
        <div className="sol-collector">
            <h3>Collect SOL</h3>
            <button onClick={handleCollect} disabled={isCollecting}>
                {isCollecting ? 'Collecting...' : 'Collect SOL'}
            </button>
            {isCollecting && (
                <div className="progress-bar">
                    <div className="progress-bar-fill" style={{width: `${progress}%`}}></div>
                </div>
            )}
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default SolCollector;
