import React, { useState, useEffect } from 'react';
import axios from 'axios';
import WalletList from './WalletList';

const PumpVolume = ({ username }) => {
    const [formData, setFormData] = useState({
        amount: '',
        tokenAddress: '',
        delay: '',
        sellDelay: '',
        slippage: '',
        priorityFee: '',
        useJito: false
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchEnvData();
    }, []);

    const fetchEnvData = async () => {
        try {
            const response = await axios.get('https://calm-peak-49513-45b1323afa7e.herokuapp.com/api/env-data');
            setFormData(prevState => ({
                ...prevState,
                ...response.data
            }));
        } catch (error) {
            console.error('Error fetching env data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://calm-peak-49513-45b1323afa7e.herokuapp.com/api/pump-volume', {
                ...formData,
                username
            });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || error.message}`);
        }
    };

    return (
        <div className="pump-volume-section">
            <h2>Pump Volume</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                    placeholder="Amount"
                    required
                />
                <input
                    type="text"
                    name="tokenAddress"
                    value={formData.tokenAddress}
                    onChange={handleInputChange}
                    placeholder="Token Address"
                    required
                />
                <input
                    type="number"
                    name="delay"
                    value={formData.delay}
                    onChange={handleInputChange}
                    placeholder="Delay"
                    required
                />
                <input
                    type="number"
                    name="sellDelay"
                    value={formData.sellDelay}
                    onChange={handleInputChange}
                    placeholder="Sell Delay"
                    required
                />
                <input
                    type="number"
                    name="slippage"
                    value={formData.slippage}
                    onChange={handleInputChange}
                    placeholder="Slippage"
                    required
                />
                <input
                    type="number"
                    name="priorityFee"
                    value={formData.priorityFee}
                    onChange={handleInputChange}
                    placeholder="Priority Fee"
                    required
                />
                <label>
                    <input
                        type="checkbox"
                        name="useJito"
                        checked={formData.useJito}
                        onChange={handleInputChange}
                    />
                    Use Jito
                </label>
                <button type="submit">Start Pump Volume</button>
            </form>
            {message && <p className="message">{message}</p>}
            
            {/* Wallet List Component */}
            <div className="wallet-list-container">
                <h3>Your Wallets</h3>
                <WalletList username={username} />
            </div>
        </div>
    );
};

export default PumpVolume;
