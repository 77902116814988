import React, { useState } from 'react';
import axios from 'axios';

const TokenSniper = ({ username }) => {
    const [tokenAddress, setTokenAddress] = useState('');
    const [buyAmount, setBuyAmount] = useState('');
    const [sellPercentage, setSellPercentage] = useState('');
    const [numWallets, setNumWallets] = useState('');
    const [strategy, setStrategy] = useState('moderate');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleTokenSnipe = async () => {
        setMessage('');
        setError('');
        try {
            const response = await axios.post('https://calm-peak-49513-45b1323afa7e.herokuapp.com/token-snipe', {
                username,
                tokenAddress,
                buyAmount: parseFloat(buyAmount),
                sellPercentage: parseFloat(sellPercentage),
                numWallets: parseInt(numWallets),
                strategy
            });
            setMessage(response.data.message);
        } catch (error) {
            setError('Error executing token sniping: ' + (error.response?.data?.message || error.message));
        }
    };

    return (
        <div className="token-sniper">
            <h3>Token Sniping</h3>
            <input
                type="text"
                value={tokenAddress}
                onChange={(e) => setTokenAddress(e.target.value)}
                placeholder="Token Address"
            />
            <input
                type="number"
                value={buyAmount}
                onChange={(e) => setBuyAmount(e.target.value)}
                placeholder="Buy Amount (SOL)"
            />
            <input
                type="number"
                value={sellPercentage}
                onChange={(e) => setSellPercentage(e.target.value)}
                placeholder="Sell Percentage"
            />
            <input
                type="number"
                value={numWallets}
                onChange={(e) => setNumWallets(e.target.value)}
                placeholder="Number of Wallets"
            />
            <select value={strategy} onChange={(e) => setStrategy(e.target.value)}>
                <option value="aggressive">Aggressive</option>
                <option value="moderate">Moderate</option>
                <option value="slow">Slow</option>
            </select>
            <button onClick={handleTokenSnipe}>Execute Token Sniping</button>
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default TokenSniper;
