import React from 'react';

const TokenHoldings = ({ data }) => {
    if (!data) return <div>Loading token holdings...</div>;

    return (
        <div className="token-holdings">
            <h2>Token Holdings</h2>
            <table>
                <thead>
                    <tr>
                        <th>Token</th>
                        <th>Quantity</th>
                        <th>Market Value (SOL)</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((token, index) => (
                        <tr key={index}>
                            <td>{token.symbol}</td>
                            <td>{token.quantity}</td>
                            <td>{token.marketValue} SOL</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TokenHoldings;
