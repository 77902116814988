import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import { jwtDecode } from "jwt-decode";
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Sidebar from './components/Sidebar';
import WalletList from './components/WalletList';
import TokenSniper from './components/TokenSniper';
import SettingsModal from './components/SettingsModal';
import SolDistributor from './components/SolDistributor';
import SolCollector from './components/SolCollector';
import WalletManager from './components/WalletManager';
import PumpVolume from './components/PumpVolume';
import api from './components/utils/api';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');
        const storedUsername = localStorage.getItem('username');

        if (token && refreshToken && storedUsername) {
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken.exp * 1000 < Date.now()) {
                    api.post('/refresh-token', { refreshToken })
                        .then(response => {
                            localStorage.setItem('token', response.data.token);
                            setIsLoggedIn(true);
                            setUsername(storedUsername);
                        })
                        .catch(error => {
                            console.error('Error refreshing token:', error);
                            handleLogout();
                        });
                } else {
                    setIsLoggedIn(true);
                    setUsername(storedUsername);
                }
                api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            } catch (error) {
                console.error('Error decoding token:', error);
                handleLogout();
            }
        }
    }, []);

    const handleLogin = (loggedInUsername) => {
        setIsLoggedIn(true);
        setUsername(loggedInUsername);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setUsername('');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('username');
        delete api.defaults.headers.common['Authorization'];
    };

    const toggleSettings = () => {
        setIsSettingsOpen(!isSettingsOpen);
    };

    return (
        <Router>
            <div className="app-container">
                {isLoggedIn && <Sidebar />}
                <div className="main-content">
                    <Routes>
                        <Route path="/login" element={
                            isLoggedIn ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />
                        } />
                        <Route path="/register" element={
                            isLoggedIn ? <Navigate to="/dashboard" /> : <Register />
                        } />
                        <Route path="/dashboard" element={
                            isLoggedIn ? (
                                <Dashboard 
                                    username={username} 
                                    onLogout={handleLogout}
                                />
                            ) : <Navigate to="/login" />
                        } />
                        <Route path="/wallets" element={
                            isLoggedIn ? (
                                <>
                                    <WalletList username={username} />
                                    <SolDistributor username={username} />
                                    <SolCollector username={username} />
                                    <WalletManager username={username} />
                                </>
                            ) : <Navigate to="/login" />
                        } />
                        <Route path="/token-sniper" element={
                            isLoggedIn ? <TokenSniper username={username} /> : <Navigate to="/login" />
                        } />
                        <Route path="/pump-volume" element={
                            isLoggedIn ? <PumpVolume username={username} /> : <Navigate to="/login" />
                        } />
                        <Route path="/settings" element={
                            isLoggedIn ? (
                                <>
                                    <button onClick={toggleSettings}>Open Settings</button>
                                    <SettingsModal isOpen={isSettingsOpen} onClose={toggleSettings} />
                                </>
                            ) : <Navigate to="/login" />
                        } />
                        <Route path="/" element={
                            <Navigate to={isLoggedIn ? "/dashboard" : "/login"} />
                        } />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
