import React, { useState } from 'react';
import { FaPlus, FaFileExport, FaTrash } from 'react-icons/fa';
import { generateWallets, exportWallets, clearWallets } from './utils/api';

const WalletManager = () => {
    const [numWallets, setNumWallets] = useState(10);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [isClearing, setIsClearing] = useState(false);
    const username = localStorage.getItem('username');

    const handleGenerateWallets = async () => {
        setMessage('');
        setError('');
        setIsGenerating(true);
        try {
            const response = await generateWallets(username, numWallets);
            setMessage(response.message);
        } catch (error) {
            setError('Error generating wallets: ' + (error.response?.data?.message || error.message));
        } finally {
            setIsGenerating(false);
        }
    };

    const handleExportToCSV = async () => {
        setMessage('');
        setError('');
        setIsExporting(true);
        try {
            await exportWallets(username);
            setMessage('Wallets exported successfully. Check your downloads.');
        } catch (error) {
            setError('Error exporting wallets: ' + (error.response?.data?.message || error.message));
        } finally {
            setIsExporting(false);
        }
    };

    const handleClearWallets = async () => {
        setMessage('');
        setError('');
        setIsClearing(true);
        try {
            const response = await clearWallets(username);
            setMessage(response.message);
        } catch (error) {
            setError('Error clearing wallets: ' + (error.response?.data?.message || error.message));
        } finally {
            setIsClearing(false);
        }
    };

    return (
        <div className="wallet-manager">
            <h3>Wallet Manager</h3>
            <div className="wallet-manager-controls">
                <div className="input-group">
                    <label htmlFor="numWallets">Number of Wallets:</label>
                    <input
                        type="number"
                        id="numWallets"
                        value={numWallets}
                        onChange={(e) => setNumWallets(parseInt(e.target.value))}
                        min="1"
                    />
                </div>
                <button onClick={handleGenerateWallets} disabled={isGenerating} className="generate-button">
                    <FaPlus /> {isGenerating ? 'Generating...' : 'Generate Wallets'}
                </button>
                <button onClick={handleExportToCSV} disabled={isExporting} className="export-button">
                    <FaFileExport /> {isExporting ? 'Exporting...' : 'Export to CSV'}
                </button>
                <button onClick={handleClearWallets} disabled={isClearing} className="clear-button">
                    <FaTrash /> {isClearing ? 'Clearing...' : 'Clear All Wallets'}
                </button>
            </div>
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default WalletManager;
