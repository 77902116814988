import React from 'react';

const TokenSalesCollection = ({ data }) => {
    if (!data) return <div>Loading token sales and collection data...</div>;

    return (
        <div className="token-sales-collection">
            <h2>Token Sales & Collection</h2>
            <div className="stats-grid">
                <div className="stat-item">
                    <h3>Total Tokens Sold</h3>
                    <p>{data.totalTokensSold}</p>
                </div>
                <div className="stat-item">
                    <h3>Total SOL Collected from Sells</h3>
                    <p>{data.totalSolCollected} SOL</p>
                </div>
                <div className="stat-item">
                    <h3>Collected SOL</h3>
                    <p>{data.collectedSol} SOL</p>
                </div>
            </div>
            <div className="activity-stats">
                <h3>Distribution & Collection Activities</h3>
                <p>Number of Distributions: {data.numberOfDistributions}</p>
                <p>Average SOL Distributed: {data.averageSolDistributed} SOL</p>
                <p>Collection Count: {data.collectionCount}</p>
            </div>
        </div>
    );
};

export default TokenSalesCollection;
