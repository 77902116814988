import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://calm-peak-49513-45b1323afa7e.herokuapp.com';

const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const getToken = () => localStorage.getItem('token');

api.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await api.post('/refresh-token', { refreshToken });
                const { token } = response.data;
                localStorage.setItem('token', token);
                api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                return api(originalRequest);
            } catch (refreshError) {
                console.error('Error refreshing token:', refreshError);
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export const login = async (username, password) => {
    try {
        const response = await api.post('/login', { username, password });
        return response.data;
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

export const register = async (username, password) => {
    try {
        const response = await api.post('/register', { username, password });
        return response.data;
    } catch (error) {
        console.error('Registration error:', error);
        throw error;
    }
};

export const fetchDashboardData = async (username, timeRange = 'all') => {
    try {
        const response = await api.get(`/api/dashboard`, { 
            params: { username, timeRange }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching dashboard data:', error);
        throw error;
    }
};

export const fetchWallets = async (username, page = 1, limit = 20, searchTerm = '') => {
    try {
        const response = await api.get('/api/wallets', {
            params: { username, page, limit, searchTerm }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching wallets:', error);
        throw error;
    }
};

export const generateWallets = async (username, numWallets) => {
    try {
        const response = await api.post('/api/generate-wallets', { username, numWallets });
        return response.data;
    } catch (error) {
        console.error('Error generating wallets:', error);
        throw error;
    }
};

export const distributeSOL = async (username, amount) => {
    try {
        const response = await api.post('/api/distribute-sol', { username, amount });
        return response.data;
    } catch (error) {
        console.error('Error distributing SOL:', error);
        throw error;
    }
};


export const collectSOL = async (username) => {
    try {
        const response = await api.post('/api/collect-sol', { username });
        return response.data;
    } catch (error) {
        console.error('Error collecting SOL:', error);
        throw error;
    }
};

export const executeTokenSniping = async (username, tokenAddress, buyAmount, sellPercentage, numWallets, strategy) => {
    try {
        const response = await api.post('/api/token-snipe', {
            username,
            tokenAddress,
            buyAmount,
            sellPercentage,
            numWallets,
            strategy
        });
        return response.data;
    } catch (error) {
        console.error('Error executing token sniping:', error);
        throw error;
    }
};

export const clearWallets = async (username) => {
    try {
        const response = await api.post('/api/clear-wallets', { username });
        return response.data;
    } catch (error) {
        console.error('Error clearing wallets:', error);
        throw error;
    }
};

export const exportWallets = async (username) => {
    try {
        const response = await api.get(`/api/export-wallets`, {
            params: { username },
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${username}_wallets.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error exporting wallets:', error);
        throw error;
    }
};

export const pumpVolume = async (username, params) => {
    try {
        const response = await api.post('/api/pump-volume', { username, ...params });
        return response.data;
    } catch (error) {
        console.error('Error in pump volume operation:', error);
        throw error;
    }
};

export const fetchEnvData = async () => {
    try {
        const response = await api.get('/api/env-data');
        return response.data;
    } catch (error) {
        console.error('Error fetching environment data:', error);
        throw error;
    }
};

export const setPrivateKey = async (privateKey) => {
    try {
        const response = await api.post('/api/set-private-key', { privateKey });
        return response.data;
    } catch (error) {
        console.error('Error setting private key:', error);
        throw error;
    }
};

export const setRecipientAddress = async (recipientAddress) => {
    try {
        const response = await api.post('/api/set-recipient-address', { recipient: recipientAddress });
        return response.data;
    } catch (error) {
        console.error('Error setting recipient address:', error);
        throw error;
    }
};

export default api;
