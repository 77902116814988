import React from 'react';
import { Line, Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement);

const ChartVisualization = ({ data }) => {
    if (!data) return <div>Loading charts...</div>;

    const solDistributionData = {
        labels: data.solDistribution.labels,
        datasets: [{
            label: 'SOL Distribution',
            data: data.solDistribution.values,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    };

    const tokenPurchasesData = {
        labels: data.tokenPurchases.labels,
        datasets: [{
            data: data.tokenPurchases.values,
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)'
            ]
        }]
    };

    const walletBalancesData = {
        labels: data.walletBalances.labels,
        datasets: [{
            label: 'Wallet Balances',
            data: data.walletBalances.values,
            backgroundColor: 'rgba(75, 192, 192, 0.6)'
        }]
    };

    return (
        <div className="chart-visualization">
            <div className="chart">
                <h3>SOL Distribution Over Time</h3>
                <Line data={solDistributionData} />
            </div>
            <div className="chart">
                <h3>Token Purchases by Token Type</h3>
                <Pie data={tokenPurchasesData} />
            </div>
            <div className="chart">
                <h3>Wallet Balances</h3>
                <Bar data={walletBalancesData} />
            </div>
        </div>
    );
};

export default ChartVisualization;
