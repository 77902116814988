import React from 'react';
import { FaWallet, FaExchangeAlt, FaCoins } from 'react-icons/fa';

const WalletOverview = ({ data }) => {
    if (!data) return <div>Loading wallet overview...</div>;

    return (
        <div className="wallet-overview">
            <h2>Wallet Overview</h2>
            <div className="overview-stats">
                <div className="stat-item">
                    <FaWallet size={24} />
                    <h3>Wallets Created</h3>
                    <p>{data.totalWallets}</p>
                </div>
                <div className="stat-item">
                    <FaExchangeAlt size={24} />
                    <h3>SOL Distributed</h3>
                    <p>{data.totalSolDistributed} SOL</p>
                </div>
                <div className="stat-item">
                    <FaCoins size={24} />
                    <h3>Total SOL Held</h3>
                    <p>{data.totalSolHeld} SOL</p>
                </div>
            </div>
            <div className="wallet-balances">
                <h3>Current Wallet Balances</h3>
                <ul>
                    {data.walletBalances.map((wallet, index) => (
                        <li key={index}>
                            <span>{wallet.address.slice(0, 6)}...{wallet.address.slice(-4)}</span>
                            <span>{wallet.balance} SOL</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default WalletOverview;
