import React, { useState, useEffect, useCallback } from 'react';
import { FaSync, FaSearch, FaWallet } from 'react-icons/fa';
import { fetchWallets } from './utils/api';

const WalletList = () => {
    const [wallets, setWallets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [refreshing, setRefreshing] = useState(false);
    const username = localStorage.getItem('username');

    const fetchWalletsData = useCallback(async (pageNumber = 1, refresh = false, search = '') => {
        setLoading(true);
        setError('');
        try {
            const response = await fetchWallets(username, pageNumber, 20, search);
            if (refresh) {
                setWallets(response.wallets);
            } else {
                setWallets(prevWallets => [...prevWallets, ...response.wallets]);
            }
            setTotalPages(response.totalPages);
            setPage(response.currentPage);
        } catch (error) {
            console.error('Error fetching wallet data:', error);
            setError('Error fetching wallet data. Please try again.');
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    }, [username]);

    useEffect(() => {
        fetchWalletsData(1, true, searchTerm);
    }, [fetchWalletsData, searchTerm]);

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (scrollHeight - scrollTop === clientHeight) {
            if (page < totalPages) {
                fetchWalletsData(page + 1, false, searchTerm);
            }
        }
    };

    const handleRefresh = () => {
        setRefreshing(true);
        setPage(1);
        fetchWalletsData(1, true, searchTerm);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="wallet-list-section">
            <div className="section-header">
                <h2 className="section-title">Wallet List for {username}</h2>
                <div className="search-refresh-container">
                    <div className="search-container">
                        <FaSearch className="search-icon" />
                        <input
                            type="text"
                            placeholder="Search wallets..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className="search-input"
                        />
                    </div>
                    <button onClick={handleRefresh} className="refresh-button" disabled={refreshing}>
                        <FaSync className={refreshing ? 'rotate' : ''} />
                    </button>
                </div>
            </div>
            <div className="wallet-list" onScroll={handleScroll}>
                {loading && !refreshing ? (
                    <div className="loading">Loading wallets...</div>
                ) : error ? (
                    <p className="error-message">{error}</p>
                ) : wallets.length > 0 ? (
                    wallets.map((wallet, index) => (
                        <div key={index} className="wallet-row">
                            <div className="wallet-address">
                                <FaWallet className="wallet-icon" />
                                {wallet.publicKey}
                            </div>
                            <div className="wallet-balance">
                                <img src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png" alt="SOL" className="token-icon" />
                                {wallet.balance}
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No wallets found.</p>
                )}
                {loading && page > 1 && <div className="loading">Loading more...</div>}
            </div>
            {page < totalPages && !loading && (
                <button onClick={() => fetchWalletsData(page + 1, false, searchTerm)} className="load-more-button">
                    Load More
                </button>
            )}
        </div>
    );
};

export default WalletList;
