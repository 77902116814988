import React, { useState, useEffect, useCallback } from 'react';
import { FaSync, FaSignOutAlt } from 'react-icons/fa';
import { fetchDashboardData } from './utils/api';
import WalletOverview from './WalletOverview';
import TransactionStatistics from './TransactionStatistics';
import TokenHoldings from './TokenHoldings';
import TokenSalesCollection from './TokenSalesCollection';
import ChartVisualization from './ChartVisualization';

const Dashboard = ({ onLogout }) => {
    const [dashboardData, setDashboardData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refreshing, setRefreshing] = useState(false);
    const username = localStorage.getItem('username');

    const loadDashboardData = useCallback(async () => {
        const token = localStorage.getItem('token');
        console.log('Token retrieved from localStorage:', token);
        if (!token) {
            setError('No token found. Please log in again.');
            onLogout();
            return;
        }
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetchDashboardData(username);
            setDashboardData(response);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access, logging out.');
                onLogout();
            } else {
                setError('Failed to load dashboard data. Please try again later.');
            }
        } finally {
            setIsLoading(false);
            setRefreshing(false);
        }
    }, [username, onLogout]);

    useEffect(() => {
        loadDashboardData();
    }, [loadDashboardData]);

    const handleRefresh = () => {
        setRefreshing(true);
        loadDashboardData();
    };

    if (isLoading && !dashboardData) {
        return <div className="loading">Loading dashboard...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <div className="dashboard">
            <header className="dashboard-header">
                <h1>Welcome, {username}!</h1>
                <div className="dashboard-actions">
                    <button onClick={handleRefresh} className="refresh-button" disabled={refreshing}>
                        <FaSync className={refreshing ? 'rotate' : ''} />
                        {refreshing ? 'Refreshing...' : 'Refresh'}
                    </button>
                    <button onClick={onLogout} className="logout-button">
                        <FaSignOutAlt />
                        Logout
                    </button>
                </div>
            </header>
            <div className="dashboard-content">
                <div className="dashboard-overview">
                    <WalletOverview data={dashboardData.walletOverview} />
                    <TransactionStatistics data={dashboardData.transactionStatistics} />
                </div>
                <div className="dashboard-details">
                    <TokenHoldings data={dashboardData.tokenHoldings} />
                    <TokenSalesCollection data={dashboardData.tokenSalesCollection} />
                </div>
                <ChartVisualization 
                    solDistribution={dashboardData.solDistribution}
                    tokenPurchases={dashboardData.tokenPurchases}
                    walletBalances={dashboardData.walletBalances}
                />
            </div>
            {isLoading && <div className="loading-overlay">Updating dashboard...</div>}
        </div>
    );
};

export default Dashboard;
