import React from 'react';

const TransactionStatistics = ({ data }) => {
    if (!data) return <div>Loading transaction statistics...</div>;

    return (
        <div className="transaction-statistics">
            <h2>Transaction Statistics</h2>
            <div className="stat-grid">
                <div className="stat-item">
                    <h3>Total SOL Spent on Token Buys</h3>
                    <p>{data.totalSolSpent} SOL</p>
                </div>
                <div className="stat-item">
                    <h3>Number of Token Buys</h3>
                    <p>{data.numberOfTokenBuys}</p>
                </div>
            </div>
            <div className="frequent-tokens">
                <h3>Most Frequent Tokens Purchased</h3>
                <ul>
                    {data.frequentTokens.map((token, index) => (
                        <li key={index}>{token.symbol}: {token.count} times</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TransactionStatistics;
