import React, { useState } from 'react';
import { setPrivateKey, setRecipientAddress } from './utils/api';

const SettingsModal = ({ isOpen, onClose }) => {
    const [privateKey, setPrivateKeyState] = useState('');
    const [recipientAddress, setRecipientAddressState] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSetPrivateKey = async () => {
        try {
            const response = await setPrivateKey(privateKey);
            setMessage(response.message);
        } catch (error) {
            setError('Error setting private key: ' + (error.response?.data?.message || error.message));
        }
    };

    const handleSetRecipientAddress = async () => {
        try {
            const response = await setRecipientAddress(recipientAddress);
            setMessage(response.message);
        } catch (error) {
            setError('Error setting recipient address: ' + (error.response?.data?.message || error.message));
        }
    };

    if (!isOpen) return null;

    return (
        <div className="settings-modal active">
            <div className="settings-modal-content">
                <h3>Settings</h3>
                <input
                    type="text"
                    placeholder="Enter Private Key"
                    value={privateKey}
                    onChange={(e) => setPrivateKeyState(e.target.value)}
                    className="input-field"
                />
                <button onClick={handleSetPrivateKey} className="settings-button">
                    Set Private Key
                </button>
                <input
                    type="text"
                    placeholder="Enter Recipient Address"
                    value={recipientAddress}
                    onChange={(e) => setRecipientAddressState(e.target.value)}
                    className="input-field"
                />
                <button onClick={handleSetRecipientAddress} className="settings-button">
                    Set Recipient Address
                </button>
                {message && <p className="success-message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
                <button onClick={onClose} className="settings-close">
                    Close
                </button>
            </div>
        </div>
    );
};

export default SettingsModal;
